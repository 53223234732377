import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import NotificationMiddleware from "../middleware/NotificationMiddleware";
//const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const storeEnhancers = compose;
const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(
        thunk,
        NotificationMiddleware
    ))
);
export default store;