import {authService} from '../Helper/AuthService';
import axios from 'axios';
import { toast } from 'react-toastify'
import { BaseURL } from '../Helper/GlobalVariables';

let toastId = null;




export function PostData(type, userData) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        } )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                console.log(error)
                reject(error);
            });
    });
}


export function GetData(type) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function PutData(type, userData) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'PUT',
            data: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function DeleteData(type) {
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}



export function PostFormData(type, userData) {


    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: userData,
            headers: {
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            },
            onUploadProgress: p => {
                const progress = p.loaded / p.total;

                // check if we already displayed a toast
                if (toastId === null) {
                    toastId = toast('Upload in Progress : ' + parseFloat(progress) * 100 + '%', {
                        progress: progress
                    });
                } else {
                    toast.update(toastId, {
                        progress: progress
                    })
                }
            }
        })
            .then((res) => {
                toast.dismiss();
                toastId = null;
                if (res.status != 200) {

                }
                return res.data;
            })

            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function GetBlob(type) {

    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + authService.getToken()
            }
        })
            .then((res) => {
                if (res.status != 200) {

                }
                return res.data;
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function PostBlob(type, userData) {


    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: JSON.stringify(userData),
            headers: {
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE',
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                if (res.status != 200) {

                }
                return res.data;
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function SecuredPostData(type, userData) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            }
        })
        .then((res) => {
            if(res.status != 200){

            }
            return res.data;
        } )
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                if(error.response.status == 401){
                   //authService.logout()
                }
                reject(error);
            });
    });
}


export function SecuredGetData(type) {
    
    
    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            }
        })
        .then((res) => {
       

            if(res.status == 401){
                // logout
                authService.logout()
            }
            if(res.status != 200){

            }
            return res.data;
        })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
            
            if(error.response.status == 401){
                //authService.logout()
            }
                
                reject(error);
            });
    });
}

export function SecuredPostFormData(type, userData) {


    return new Promise((resolve, reject) => {
        axios({
            url: BaseURL + type,
            method: 'POST',
            data: userData,
            headers: {
                'Authorization': 'Bearer ' + authService.getToken(),
                'Unit': 'EMPLOYEE'
            },
            onUploadProgress: p => {
                const progress = p.loaded / p.total;

               
            }
        })
            .then((res) => {
                
                if (res.status != 200) {

                }
                return res.data;
            })

            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}