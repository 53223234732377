import React from 'react'
import { Button, notification } from 'antd';
import { useSelector } from 'react-redux';
import { T_STATUS_SUCCESS, T_STATUS_ERROR, T_STATUS_WARNING, T_STATUS_INFO } from '../../redux/constants/action-types';
import { useNavigation } from 'react-navi';





export default function NotificationSnack() {
    const notification_type = useSelector((state) => state.notification_type);
    const notification_status = useSelector((state) => state.notification_status);
    const notification_text = useSelector((state) => state.notification_text);
    const notification_link = useSelector((state) => state.notification_link);
    const action = useSelector((state) => state.notification_action);

    const nav = useNavigation()

    const btn = (notification_link) => (
        <Button type="primary" size="small" onClick={() => nav.navigate(notification_link)}>
          Clike Here
        </Button>
      );

    const args = {
        message: notification_status,
        description: notification_text,
        key: `${new Date().getTime()}`,
        btn: action ?  btn(notification_link): null
    };
    if (notification_status != '' && notification_type != '') {

        if(notification_type == T_STATUS_SUCCESS){
            notification.success(args);
        } else if(notification_type == T_STATUS_ERROR){
            notification.error(args);
        } else if(notification_type == T_STATUS_WARNING){
            notification.warning(args);
        } else if (notification_type == T_STATUS_INFO){
            notification.info(args);
        } else{
            notification.open(args);
        }
        
    }

    return <></>;
}