import { T_STATUS_SUCCESS, T_STATUS_ERROR, T_STATUS_WARNING, T_STATUS_SHOW, T_STATUS_LOADING, T_STATUS_CLOSE_LOADING, T_STATUS_INFO } from "../constants/action-types";
import { message } from "antd";




const NotificationMiddleware = (store) => (next) => (action) => {


  // for toast notifications

  if (action.type == T_STATUS_SHOW) {
    let status = action.payload.status;
    let text = action.payload.text;

    if (status == T_STATUS_SUCCESS) {
      message.success(text);
    } else if (status == T_STATUS_ERROR) {
      message.error(text);
    } else if (status == T_STATUS_WARNING) {
      message.warning(text);
    } else if (status == T_STATUS_INFO) {
      message.info(text);
    }
  }


  // for loading toast

  if (action.type == T_STATUS_LOADING) {
    let text = action.payload.text;
    message.loading(text, 0);
  }


  // close loading

  if (action.type == T_STATUS_CLOSE_LOADING) {
    message.destroy();
  }


  


  next(action)
}
export default NotificationMiddleware